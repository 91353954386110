import React from 'react';
import { Rules } from '../../containers/Clients/Rules';
import { Groups } from '../../containers/Clients/Groups';
import { Levels } from '../../containers/Clients/Levels';
import { Time } from '../../containers/Clients/Time';
import { PageTabs } from '../../containers/PageTabs';
import classes from './Clients.module.scss';

export const Clients = () => {
  const availableTabs = [
    {
      key: '1',
      name: 'Rules',
      element: <Rules />,
    },
    {
      key: '2',
      name: 'Groups',
      element: <Groups />,
    },
    {
      key: '3',
      name: 'Levels',
      element: <Levels />,
    },
    {
      key: '4',
      name: 'Time',
      element: <Time />,
    },
  ];

  return (
    <div className={classes.root}>
      <h1 data-testid="clients">Clients</h1>
      <PageTabs tabs={availableTabs} />
    </div>
  );
};
