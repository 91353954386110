import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: [],
  users: [],
  commissions: [],
  firstTime: [],
  firstTimeConfig: [],
  settings: [],
};

export const commonSlice = createSlice({
  name: 'common',
  initialState: initialState,
  reducers: {
    getCategories: (state) => state,
    getCategoriesSuccess: (state, action) => {
      const { payload: categories } = action;
      return { ...state, categories };
    },
    getCategoriesError: (state, action) => state,

    getUsers: (state) => state,
    getUsersSuccess: (state, action) => {
      const { payload: users } = action;
      return { ...state, users };
    },
    getUsersError: (state, action) => state,

    getCommissions: (state) => state,
    getCommissionsSuccess: (state, action) => {
      const { payload: commissions } = action;
      return { ...state, commissions };
    },
    getCommissionsError: (state, action) => state,

    getfirstTimeConfig: (state) => state,
    getFTConfigSuccess: (state, action) => {
      const { payload: firstTimeConfig } = action;
      return { ...state, firstTimeConfig: [firstTimeConfig] };
    },
    getFTConfigError: (state, action) => state,

    getfirstTime: (state) => state,
    getFTSuccess: (state, action) => {
      const { payload: firstTime } = action;
      return { ...state, firstTime };
    },
    getFTError: (state, action) => state,

    getIsDry: (state) => state,
    getIsDrySuccess: (state, action) => {
      const { payload: isDry } = action;
      return { ...state, settings: [isDry] };
    },
    getIsDryError: (state, action) => state,
  },
});

export const commonActions = { ...commonSlice.actions };
export default commonSlice.reducer;
