import { call, put, takeLatest } from 'redux-saga/effects';
import { commonActions } from '../../store/common.slice';
import {getBOCategories} from '../../services/common.serv'

function* fetchCategories() {
  try {
    const getCategories = () => getBOCategories();
    const { data } = yield call(getCategories);

    yield put(commonActions.getCategoriesSuccess(data));
  } catch (error) {
    yield put(commonActions.getCategoriesError(error));
  }
}

export function* commonSaga() {
  yield takeLatest(commonActions.getCategories.type, fetchCategories);
}
