import { call, put, takeLatest } from 'redux-saga/effects';
import { authActions } from '../../store/auth.slice';
import { Auth } from '../../services/auth.serv';

function* authLogin(action) {
  try {
    const { payload } = action;
    const userLogin = () => Auth.login({...payload});
    const { data } = yield call(userLogin);
    
    localStorage.setItem('tkn', data.token);
    yield put(authActions.loginSuccess(data.token));
  } catch (error) {
    yield put(authActions.loginError(error));
  }
}

function* authIsLogged(action) {
  try {
    const { payload } = action;
    const userLogged = () => Auth.isLogged(payload);
    const { data } = yield call(userLogged);
    
    yield put(authActions.isLoggedSuccess(data.isLogged));
  } catch (error) {
    localStorage.removeItem('tkn');
    yield put(authActions.isLoggedError(error));
  }
}

export function* userLoginSaga() {
  yield takeLatest(authActions.login.type, authLogin);
  yield takeLatest(authActions.isLogged.type, authIsLogged);
}
