import axios from 'axios';

const ApiUrl = process.env.REACT_APP_API_URL;
const authToken = process.env.REACT_APP_AUTH_TOKEN;
const authHeader = {
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
};

const baseUrl = `${ApiUrl}/clients`;

export const Groups = {
  getCommissionGroups: () =>
    axios.get(`${baseUrl}/commission/groups`, authHeader),
  createCommissionGroup: (group) =>
    axios.post(`${baseUrl}/commission/groups`, group, authHeader),
  updateCommissionGroup: (groupId, group) =>
    axios.put(`${baseUrl}/commission/groups/${groupId}`, group, authHeader),
  deleteCommissionGroup: (groupId) =>
    axios.delete(`${baseUrl}/commission/groups/${groupId}`, authHeader),
};

export const Levels = {
  getAllCommissionLevels: () =>
    axios.get(`${baseUrl}/commission/levels`, authHeader),
  getCommissionLevels: (groupId) =>
    axios.get(`${baseUrl}/commission/levels/${groupId}`, authHeader),
  createCommissionLevel: (level) =>
    axios.post(`${baseUrl}/commission/levels`, level, authHeader),
  updateCommissionLevel: (levelId, level) =>
    axios.put(`${baseUrl}/commission/levels/${levelId}`, level, authHeader),
  deleteCommissionLevel: (levelId) =>
    axios.delete(`${baseUrl}/commission/levels/${levelId}`, authHeader),
};

export const Time = {
  getAllCommissionTime: () =>
    axios.get(`${baseUrl}/commission/time`, authHeader),
  getCommissionTime: (groupId) =>
    axios.get(`${baseUrl}/commission/time/${groupId}`, authHeader),
  createCommissionTime: (time) =>
    axios.post(`${baseUrl}/commission/time`, time, authHeader),
  updateCommissionTime: (timeId, time) =>
    axios.put(`${baseUrl}/commission/time/${timeId}`, time, authHeader),
  deleteCommissionTime: (timeId) =>
    axios.delete(`${baseUrl}/commission/time/${timeId}`, authHeader),
};

export const getAllRules = () =>
  axios.get(`${baseUrl}/commission/rules`, authHeader);

export const getCategories = () =>
  axios.get(`${baseUrl}/categories`, authHeader);
