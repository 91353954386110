import React, { useState } from 'react';
import { Select } from 'antd';
const { Option } = Select;

export const SelectInput = ({
  type,
  groups,
  selected,
  handleSelect,
  selectFields,
}) => {
  const [selOption, setSelOption] = useState(selected[type]);
  let options = [];

  switch (type) {
    case 'comm_group':
      options = groups.map((group) => {
        return {
          value: group.id,
          label: group.name,
        };
      });
      break;
    case 'type':
      options = [
        { value: 'PERCENT', label: 'Percentage' },
        { value: 'FIXED', label: 'Fixed Value' },
      ];
      break;
    default:
      break;
  }

  const handleOnSelect = (value, label) => {
    setSelOption(value);
    handleSelect({ ...selectFields, [type]: value });
  };

  return (
    <Select defaultValue={selOption} onSelect={handleOnSelect}>
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};
