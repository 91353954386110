import React from 'react';
import { Layout } from 'antd';
import classes from './MainFooter.module.scss';

const { Footer } = Layout;

export const MainFooter = () => {
  return (
    <Footer className={classes.root}>
      <div>
        <small>Insparya &copy; {new Date().getFullYear()}</small>
      </div>
    </Footer>
  );
};
