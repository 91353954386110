import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: localStorage.getItem('tkn') || '',
  error: '',
};

export const authSlice = createSlice({
  name: 'partner',
  initialState: initialState,
  reducers: {
    login: (state, action) => ({...state, error: ''}),
    loginSuccess: (state, action) => {
      const { payload: token } = action;
      return { ...state, token, error: '' };
    },
    loginError: (state, action) => {
      return { ...state, token: '', error: 'Wrong credencials' };
    },

    isLogged: (state, action) => state,
    isLoggedSuccess: (state, action) => state,
    isLoggedError: (state, action) => {
      return { ...state, token: '', error: 'Error' };
    },
  },
});

export const authActions = { ...authSlice.actions };
export default authSlice.reducer;
