import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { TableSimple } from '../../containers/TableSimple';
import { commonActions } from '../../store/common.slice';
import {
  calculateAll,
  setPacientLead,
  getFirstTimeComissionsBk,
  setFirstTimeLead,
} from '../../services/common.serv';
import { openNotification } from '../../utils/notify.utils';
import { ConfigEdit } from './ConfigEdit';
import classes from './Config.module.scss';

export const Config = () => {
  const [creating, setCreating] = useState(false);
  const [isCalcAllLoading, setIsCalcAllLoading] = useState(false);
  const { settings } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!settings.length) {
      dispatch(commonActions.getIsDry());
    }
  }, [dispatch, settings]);

  const handleEdit = () => {
    setCreating(!creating);
  };

  const handleCalculateAll = async () => {
    // call api endpoint to calculate all
    setIsCalcAllLoading(true);
    try {
      const { data: crmDumpEntries } = await calculateAll();

      if (crmDumpEntries.length > 1) {
        crmDumpEntries.forEach(async (dump) => {
          const sendLeadDump = await setPacientLead(dump);
          if (sendLeadDump.status !== 200) {
            openNotification('error', 'Error calculating all');
          }
        });
      }
      setIsCalcAllLoading(false);
    } catch (error) {
      console.log('error calculate all', error);
    }
  };

  const handleCalculateAllFt = async () => {
    setIsCalcAllLoading(true);
    try {
      const { data: ftDump } = await getFirstTimeComissionsBk();
      if (ftDump.length > 1) {
        ftDump.forEach( async (dump) => {
          const parsedData = JSON.parse(dump.data);
          if (parsedData.idRef && parsedData.idLead) {
            const sentFTlead = await setFirstTimeLead({
              ...parsedData,
              date: dump.created
            });
            if (sentFTlead.status !== 200) {
              openNotification('error', 'Error calculating all');
            }
          }
        })
      setIsCalcAllLoading(false);
      }
    } catch (error) {}
  };

  const adaptedSettings = (data) => {
    const editButton = ({ edit }) => (
      <Button type="default" onClick={handleEdit}>
        <EditOutlined />
      </Button>
    );
    return data.map((setting) => {
      const { isDry } = setting;
      return {
        option: 'Active Leads to BO',
        value: (
          <span style={{ color: isDry ? 'green' : 'red' }}>{`${isDry}`}</span>
        ),
        edit: editButton({ edit: handleEdit }),
      };
    });
  };

  return (
    <div>
      {isCalcAllLoading && <div>CALC ALL LOADING...</div>}
      {creating && (
        <ConfigEdit type={'isDry'} goBack={handleEdit} classes={classes} />
      )}
      {!creating && (
        <>
          <h1 data-testid="settings">Main Settings</h1>
          {/* <div>
            <Button type="primary" onClick={handleCalculateAll}>
              Calculate All
            </Button>
          </div>
          <div>
            <Button type="secondary" onClick={handleCalculateAllFt}>
              Calculate All FT
            </Button>
          </div> */}
          <div>
            <TableSimple data={adaptedSettings(settings)} />
          </div>
        </>
      )}
    </div>
  );
};
