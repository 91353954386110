import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Select, Button, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { commonActions } from '../../store/common.slice';
import { openNotification } from '../../utils/notify.utils';
import { setIsDry } from '../../services/common.serv';

const { Option } = Select;

export const ConfigEdit = ({ type, goBack, classes }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const onFinish = async (values) => {
    const formValues = { ...values, value: values.value.toString() };

    try {
      const updateSetting = await setIsDry({
        value: parseInt(formValues.value),
      });
      if (updateSetting.status === 200) {
        openNotification('success', 'Setting updated!');
        dispatch(commonActions.getIsDry());
        goBack();
      }
    } catch (error) {
      // console.log('main settings error', error);
      openNotification('error', 'could not update this setting.');
    }
  };

  return (
    <div>
      <div className={classes.addWrap}>
        <Tooltip title="Go Back">
          <ArrowLeftOutlined
            className={classes.goBack__icon}
            onClick={goBack}
          />
        </Tooltip>
      </div>
      <div>
        <div>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
          >
            <Form.Item
              name="value"
              label="Active Leads to BO"
              rules={[{ required: true }]}
            >
              <Select placeholder="Select an option">
                <Option key={'gbserga01'} value={0}>
                  false
                </Option>
                <Option key={'gbserga01'} value={1}>
                  true
                </Option>
              </Select>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
