import React from 'react';
import { useDispatch } from 'react-redux';
import { partnersActions } from '../../../store/partners.slice';
import { Form, Input, Button, Select, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Exceptions as ExceptionsService } from '../../../services/partners.serv';
import { openNotification } from '../../../utils/notify.utils';
import classes from './Exceptions.module.scss';

export const ExceptionsForm = ({ goBack, exceptions }) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const onFinish = async (values) => {
    const formValues = {
      ...values,
      userIds: values.userIds.map((id) => parseInt(id)),
    };

    try {
      const newExceptLevel = await ExceptionsService.createCommissionExceptions(
        formValues
      );
      if (newExceptLevel.status === 200) {
        openNotification('success', 'New Group Saved');
        dispatch(partnersActions.getExceptions());
        goBack();
      }
    } catch (error) {
      // console.log('save new group error', error);
      openNotification('error', 'New Group Could not be Saved');
    }
  };

  const onReset = () => {
    form.resetFields();
    openNotification('info', 'Form cleared');
  };

  return (
    <div>
      <h4>Add new</h4>
      <div className={classes.addWrap}>
        <Tooltip title="Go Back">
          <ArrowLeftOutlined
            className={classes.goBack__icon}
            onClick={goBack}
          />
        </Tooltip>
      </div>
      <div>
        <Form
          {...layout}
          form={form}
          requiredMark="optional"
          name="control-hooks"
          onFinish={onFinish}
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input placeholder="Insert exception name" />
          </Form.Item>

          <Form.Item
            name="userIds"
            label="User Ids"
            rules={[{ required: true }]}
          >
            <Select
              mode="tags"
              tokenSeparators={[',']}
              placeholder="Type all BO user ID's that should have this exception"
              allowClear
            />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
