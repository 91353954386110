import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { partnersActions } from '../../../store/partners.slice';
import { Tooltip } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Levels as LevelService } from '../../../services/partners.serv';
import { TableEdit } from '../../TableEdit';
import { LevelsForm } from './LevelsForm';
import { openNotification } from '../../../utils/notify.utils';
import classes from './Levels.module.scss';

export const Levels = () => {
  const [creating, setCreating] = useState(false);
  const { groups } = useSelector((state) => state.partners);
  const { levels } = useSelector((state) => state.partners);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(partnersActions.getGroups());
    dispatch(partnersActions.getLevels());
  }, [dispatch]);

  const handleEdit = async (id, levelData) => {
    try {
      const update = await LevelService.updateCommissionLevel(id, levelData);
      if (update.status === 200) {
        openNotification('success', 'Updated!');
        dispatch(partnersActions.getLevels());
      }
    } catch (error) {
      // console.log('ERROR updating Level', error);
      openNotification('error', 'Ups! Could not update...');
    }
  };

  const handleRemove = async (levelId) => {
    try {
      const remove = await LevelService.deleteCommissionLevel(levelId);
      if (remove.status === 200) {
        openNotification('success', 'The level was deleted!');
        dispatch(partnersActions.getLevels());
      }
    } catch (error) {
      // console.log('ERROR deleting Group', error);
      openNotification('error', 'Ups! Could not delete this level...');
    }
  };

  const handleAddNew = () => {
    setCreating(!creating);
  };

  // Change comm_group to show Group name;
  const levelTableData = levels.map((level) => {
    const levelGroup = groups.find((group) => group.id === level.comm_group);
    return {
      ...level,
      comm_group: levelGroup ? levelGroup.name : 'Deleted Group',
    };
  });

  // const highestLevel = levels
  //   .map((level) => level.level)
  //   .reduce((prev, curr) => {
  //     if (curr > prev) return curr;
  //     return prev;
  //   }, 0);
  // console.log('levels', levels, highestLevel);

  // console.log('groups', groups);

  return (
    <div>
      <h3>Levels</h3>
      {creating && <LevelsForm goBack={handleAddNew} groups={groups} />}
      {!creating && (
        <div>
          <div className={classes.addWrap}>
            <Tooltip title="Add Level">
              <PlusCircleOutlined
                className={classes.addNew__icon}
                onClick={handleAddNew}
              />
            </Tooltip>
            <span className={classes.addNew__text}>Add New Level</span>
          </div>
          <TableEdit
            list={levelTableData}
            groups={groups}
            update={handleEdit}
            remove={handleRemove}
          />
        </div>
      )}
    </div>
  );
};
