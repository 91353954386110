import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientsActions } from '../../../store/clients.slice';
import { Loading } from '../../Loading';
import { TableSimple } from '../../TableSimple';
import { addKeys } from '../../../utils/table.utils';

export const Rules = ({ list }) => {
  const { rules } = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clientsActions.getAllRules());
  }, [dispatch]);

  return (
    <div>
      <h1>Rules</h1>
      <p>Affiliate Clients Rules Overview</p>
      {!rules.length && (
        <div>
          <Loading />
        </div>
      )}

      {rules.length > 0 &&
        rules.map((rule) => (
          <div key={rule.id}>
            <TableSimple
              title={rule.name}
              rowKey="levelId"
              data={addKeys(rule.levels)}
            />
          </div>
        ))}
    </div>
  );
};
