import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientsActions } from '../../../store/clients.slice';
import { Time as TimeService } from '../../../services/clients.serv';
import { TableEdit } from '../../TableEdit';
import { openNotification } from '../../../utils/notify.utils';
// import classes from './Time.module.scss';

export const Time = () => {
  const { groups } = useSelector((state) => state.clients);
  const { time } = useSelector((state) => state.clients);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clientsActions.getGroups());
    dispatch(clientsActions.getTime());
  }, [dispatch]);

  const handleEdit = async (id, timeData) => {
    try {
      const update = await TimeService.updateCommissionTime(id, timeData);
      if (update.status === 200) {
        openNotification('success', 'Updated!');
        dispatch(clientsActions.getTime());
      }
    } catch (error) {
      // console.log('ERROR updating Level', error);
      openNotification('error', 'Ups! Could not update...');
    }
  };

  const handleRemove = async (levelId) => {
    try {
      const remove = await TimeService.deleteCommissionTime(levelId);
      if (remove.status === 200) {
        openNotification('success', 'The level was deleted!');
        dispatch(clientsActions.getTime());
      }
    } catch (error) {
      // console.log('ERROR deleting Group', error);
      openNotification('error', 'Ups! Could not delete this level...');
    }
  };

  const timeTableData = useMemo(
    () =>
      time.map((timeItem) => {
        const timeGroup = groups.find(
          (group) => group.id === timeItem.comm_group
        );
        return {
          ...timeItem,
          comm_group: timeGroup ? timeGroup.name : 'Deleted Group',
        };
      }),
    [time, groups]
  );

  return (
    <div>
      <h3>Time</h3>
      <div>
        <TableEdit
          list={timeTableData}
          groups={groups}
          update={handleEdit}
          remove={handleRemove}
        />
      </div>
    </div>
  );
};
