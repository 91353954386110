import React from 'react';
import { Input, InputNumber, Form } from 'antd';
import { SelectInput } from './SelectInput';
import { SelectMulti } from './SelectMulti';
import { SelectMultiUsers } from './SelectMultiUsers';

export const EditCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  handleSelect,
  selected,
  groups,
  ...restProps
}) => {
  const inputElement = (inputType) => {
    switch (inputType) {
      case 'select':
        if (dataIndex === 'categories') {
          return (
            <SelectMulti
              type={dataIndex}
              selected={record}
              handleSelect={handleSelect}
            />
          );
        }

        if (dataIndex === 'userIds') {
          return (
            <SelectMultiUsers
              type={dataIndex}
              selected={record}
              handleSelect={handleSelect}
            />
          );
        }

        return (
          <SelectInput
            type={dataIndex}
            selected={record}
            selectFields={selected}
            handleSelect={handleSelect}
            groups={groups}
          />
        );
      case 'number':
        return <InputNumber />;
      default:
        return <Input />;
    }
  };

  const inputNode = inputElement(inputType);

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
