// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { partnersActions } from '../../store/partners.slice';
import classes from './Dashboard.module.scss';

export const Dashboard = () => {
  // const { rules } = useSelector((state) => state.partners);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(partnersActions.getAllRules());
  // }, [dispatch]);

  return (
    <div className={classes.root}>
      <h1>Dashboard</h1>
      <p>Affiliates Commission Middleware Configuration.</p>
      <p>Use the menu on the left hand side to chose between Partners and Clients configurations.</p>
    </div>
  );
};
