import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
const { Option } = Select;

export const SelectMulti = ({ type, selected, handleSelect }) => {
  const [selOptions, setSelOptions] = useState(selected[type]);
  const { categories } = useSelector((state) => state.common);

  useEffect(() => {
    handleSelect({[type]: selOptions});
  }, [handleSelect, selOptions, type]);

  const selectProps = {
    mode: 'multiple',
  };
  const selectedValues = selected.categories;
  const activeCategories = categories.filter((cat) => {
    return !selectedValues.find(
      (sel) => parseInt(sel.value) === parseInt(cat.tid)
    );
  });

  let options = activeCategories.map((categ) => ({
    value: categ.tid,
    label: categ.name,
  }));

  const handleOnSelect = (value, label) => {
    const addedItems = [
      ...selOptions,
      { value: label.key, label: label.title },
    ];

    setSelOptions(addedItems);
    handleSelect({
      [type]: addedItems,
    });
  };

  const handleOnDeselect = (value) => {
    const removedItems = selOptions.filter((item) => item.label !== value);
    setSelOptions(removedItems);
    handleSelect({
      [type]: removedItems,
    });
  };

  const selectValues = selOptions.map((opt) => opt.label);

  return (
    <Select
      {...selectProps}
      value={selectValues}
      onSelect={handleOnSelect}
      onDeselect={handleOnDeselect}
    >
      {options.map((option) => (
        <Option key={option.value} title={option.label} value={option.label}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};
