import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rules: [],
  groups: [],
  levels: [],
  exceptions: [],
  exceptionLevels: [],
};

export const partnerSlice = createSlice({
  name: 'partner',
  initialState: initialState,
  reducers: {
    getAllRules: (state) => state,
    getAllRulesSuccess: (state, action) => {
      const { payload: rules } = action;
      return { ...state, rules };
    },
    getAllRulesError: (state, action) => state,

    getGroups: (state) => state,
    getGroupsSuccess: (state, action) => {
      const { payload: groups } = action;
      return { ...state, groups };
    },
    getGroupsError: (state) => state,

    getLevels: (state) => state,
    getLevelsSuccess: (state, action) => {
      const { payload: levels } = action;
      return { ...state, levels };
    },
    getLevelsError: (state) => state,

    getExceptions: (state) => state,
    getExceptionsSuccess: (state, action) => {
      const { payload: exceptions } = action;
      return { ...state, exceptions };
    },
    getExceptionsError: (state) => state,

    getExceptionLevels: (state) => state,
    getExceptionLevelsSuccess: (state, action) => {
      const { payload: exceptionLevels } = action;
      return { ...state, exceptionLevels };
    },
    getExceptionLevelsError: (state) => state,
  },
});

export const partnersActions = { ...partnerSlice.actions };
export default partnerSlice.reducer;
