import React from 'react';
import { Form, Input, Button, Select, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Groups as GroupService } from '../../../services/partners.serv';
import { openNotification } from '../../../utils/notify.utils';
import classes from './Groups.module.scss';

const { Option } = Select;

export const GroupsForm = ({ goBack, categories }) => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const onCategChange = (value) => {
    // console.log('on categ change', value);
  };

  const onFinish = async (values) => {
    // console.log('send values to API', values);
    const formValues = {
      ...values,
      categories: values.categories.map((categ) => parseInt(categ)),
    };

    try {
      const newGroup = await GroupService.createCommissionGroup(formValues);
      if (newGroup.status === 200) {
        openNotification('success', 'New Group Saved');
        goBack();
      }
    } catch (error) {
      // console.log('save new group error', error);
      openNotification('error', 'New Group Could not be Saved');
    }
  };

  const onReset = () => {
    form.resetFields();
    openNotification('info', 'Form cleared');
  };

  return (
    <div>
      <h4>Add new</h4>
      <div className={classes.addWrap}>
        <Tooltip title="Go Back">
          <ArrowLeftOutlined
            className={classes.goBack__icon}
            onClick={goBack}
          />
        </Tooltip>
      </div>
      <div>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input placeholder="Group name" />
          </Form.Item>
          <Form.Item
            name="categories"
            label="Categories"
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              placeholder="Select a category"
              onChange={onCategChange}
              allowClear
            >
              {categories.map((category) => (
                <Option
                  key={category.tid}
                  value={category.tid}
                  title={category.tid}
                  lablel={category.name}
                >
                  {category.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
