import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../store/auth.slice';
import { Layout } from 'antd';
import { Sidebar } from '../containers/Sidebar';
import { MainHeader } from '../containers/MainHeader';
import { MainFooter } from '../containers/MainFooter';
import { Login } from '../page/login';
import { Dashboard } from '../page/dashboard';
import { Partners } from '../page/partners';
import { Clients } from '../page/clients';
import { Commissions } from '../page/commissions';
import { FirstTimeConfig } from '../page/first-time/config';
import { FirstTime } from '../page/first-time';

import { Config } from '../page/config';
import classes from './App.module.scss';

const { Content } = Layout;

export const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const userToken = localStorage.getItem('tkn');
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(authActions.isLogged(token));
    }
  }, [dispatch, token]);

  if (!token && !userToken) {
    return <Login />;
  }

  return (
    <Router>
      <Layout data-testid="app">
        <Sidebar collapsed={collapsed} />

        <Layout>
          <MainHeader handleSidebar={setCollapsed} collapsed={collapsed} />
          <Content className={classes.content}>
            <div className={classes.mainCard}>
              <Switch>
                <Route path="/dashboard">
                  <Dashboard />
                </Route>
                
                <Route path="/partners">
                  <Partners />
                </Route>

                <Route path="/clients">
                  <Clients />
                </Route>

                <Route path="/commissions">
                  <Commissions />
                </Route>

                <Route path="/first-time-options">
                  <FirstTimeConfig />
                </Route>

                <Route path="/first-time">
                  <FirstTime />
                </Route>

                <Route path="/settings">
                  <Config />
                </Route>

                <Route path="/">
                  <Dashboard />
                </Route>
              </Switch>
            </div>
          </Content>
          <MainFooter />
        </Layout>
      </Layout>
    </Router>
  );
};
