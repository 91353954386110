import React from 'react';
import { Form, Input, InputNumber, Button, Select, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Levels as LevelService } from '../../../services/partners.serv';
import { openNotification } from '../../../utils/notify.utils';
import classes from './Levels.module.scss';

const { Option } = Select;

export const LevelsForm = ({ goBack, groups }) => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const onGroupChange = (value) => {
    // console.log(value)
  };

  const onFinish = async (values) => {
    const formValues = { ...values, value: values.value.toString() };

    try {
      const newLevel = await LevelService.createCommissionLevel(formValues);
      if (newLevel.status === 200) {
        openNotification('success', 'New level saved!');
        goBack();
      }
    } catch (error) {
      // console.log('save new group error', error);
      openNotification('error', 'New Level could not be saved');
    }
  };

  const onReset = () => {
    form.resetFields();
    openNotification('info', 'Form cleared');
  };

  return (
    <div>
      <h4>Add new</h4>
      <div className={classes.addWrap}>
        <Tooltip title="Go Back">
          <ArrowLeftOutlined
            className={classes.goBack__icon}
            onClick={goBack}
          />
        </Tooltip>
      </div>
      <div>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input placeholder="Level name" />
          </Form.Item>
          
          <Form.Item
            name="comm_group"
            label="Group"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select a group to add this level"
              onChange={onGroupChange}
              allowClear
            >
              {groups.map((group) => (
                <Option key={group.id} value={group.id}>{group.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="level" label="Level" rules={[{ required: true }]}>
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item name="type" label="Type" rules={[{ required: true }]}>
            <Select
              placeholder="Select a value type option"
              onChange={onGroupChange}
              allowClear
            >
              <Option value="PERCENT">Percentage</Option>
              <Option value="FIXED">Fixed Value</Option>
            </Select>
          </Form.Item>
          
          <Form.Item name="value" label="Value" rules={[{ required: true }]}>
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
