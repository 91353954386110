import React from 'react';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export const PageTabs = ({ tabs }) => {
  return (
    <div>
      <Tabs defaultActiveKey="1" type="card">
        {tabs.map((tab) => (
          <TabPane tab={tab.name} key={tab.key}>
            {tab.element}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};
