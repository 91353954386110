import React, { useState, useEffect } from 'react';
import { Table, Input } from 'antd';
import { buildOrderedColumns } from '../../utils/table.utils';

const { Search } = Input;

export const TableSearch = ({ title, data, rowKey = 'id', footer = null }) => {
  const [tableData, setTableData] = useState(data);
  const tableColumns = data.length ? buildOrderedColumns(data) : [];

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const onSearch = (value) => {
    const searched = data.filter((item) => {
      const itemStr = JSON.stringify(item).toLowerCase();
      const found = itemStr.includes(value.toLowerCase());
      return found;
    });

    setTableData(searched);
  };

  return (
    <div>
      <div style={{ width: '50%', maxWidth: '400px' }}>
        <Search placeholder="type your search" onSearch={onSearch} />
      </div>
      <Table
        title={() => title}
        footer={() => footer}
        columns={tableColumns}
        dataSource={tableData}
        size="small"
        rowKey={rowKey}
      />
    </div>
  );
};
