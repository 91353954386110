import axios from 'axios';

const ApiUrl = process.env.REACT_APP_API_URL;

export const Auth = {
  login: (loginData) => axios.post(`${ApiUrl}/auth/login`, loginData),

  logout: (user) => axios.post(`${ApiUrl}/auth/logout`, { user }),

  isLogged: (token) => {
    const authHeader = {
      Authorization: `Bearer ${token}`,
    };
    return axios.get(`${ApiUrl}/auth/check`, {
      headers: authHeader,
    });
  },
};
