import { call, put, takeLatest } from 'redux-saga/effects';
import { commonActions } from '../../../store/common.slice';
// import { getAllRules, Groups, Levels, Time } from '../../services/clients.serv';
import { getFirstTimeRule } from '../../../services/common.serv';

function* fetchFirstTime() {
  try {
    const getFTCData = () => getFirstTimeRule();
    const { data } = yield call(getFTCData);

    yield put(commonActions.getFTConfigSuccess(data));
  } catch (error) {
    yield put(commonActions.getFTConfigError(error));
  }
}

export function* configFirstTimeSaga() {
  yield takeLatest(commonActions.getfirstTimeConfig.type, fetchFirstTime);
  
}
