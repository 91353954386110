import React from 'react';
import { Popconfirm, Typography, Tooltip, Button } from 'antd';
import { EditOutlined, SaveOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import classes from './TableEdit.module.scss'

export const ActionCell = ({
  record,
  save,
  edit,
  cancel,
  removeRow,
  editingKey,
  editable,
}) => {
  if (!editable) {
    return (
      <Tooltip title="Edit">
        <Typography.Link
          disabled={editingKey !== ''}
          onClick={() => edit(record)}
        >
          <EditOutlined />
        </Typography.Link>
      </Tooltip>
    );
  }

  return (
    <span>
      <Tooltip title="Save">
        <Button className={classes.actionBtn} icon={<SaveOutlined className={classes.actionBtn__save} />} onClick={() => save(record.id)}/>
      </Tooltip>
      
      <Popconfirm title="Sure to delete row?" onConfirm={() => removeRow(record.id)}>
        <Tooltip title="Delete">
          <Button className={classes.actionBtn} icon={<DeleteOutlined className={classes.actionBtn__del} />} danger />
        </Tooltip>
      </Popconfirm>

      <Popconfirm title="Sure to cancel row edit?" onConfirm={cancel}>
        <Tooltip title="Cancel">
          <Button className={classes.actionBtn} icon={<CloseOutlined className={classes.actionBtn__cancel} />} />
        </Tooltip>
      </Popconfirm>
    </span>
  );
};
