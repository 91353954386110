import { call, put, takeLatest } from 'redux-saga/effects';
import { commonActions } from '../../store/common.slice';
// import { getAllRules, Groups, Levels, Time } from '../../services/clients.serv';
import { getCalculatedCommissions } from '../../services/common.serv';

function* fetchCommissions() {
  try {
    const getCommissions = () => getCalculatedCommissions();
    const { data } = yield call(getCommissions);

    yield put(commonActions.getCommissionsSuccess(data));
  } catch (error) {
    yield put(commonActions.getCommissionsError(error));
  }
}

// GROUPS
// function* fetchGroups() {
//   const { getCommissionGroups } = Groups;
//   try {
//     const getClientsGroups = () => getCommissionGroups();
//     const { data } = yield call(getClientsGroups);

//     yield put(clientsActions.getGroupsSuccess(data));
//   } catch (error) {
//     yield put(clientsActions.getGroupsError(error));
//   }
// }

export function* commissionsSaga() {
  yield takeLatest(commonActions.getCommissions.type, fetchCommissions);
  
}
