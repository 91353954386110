import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { commonActions } from '../../../store/common.slice';
import { openNotification } from '../../../utils/notify.utils';
import { setFirstTimeRule } from '../../../services/common.serv';

export const FirstTimeConfigEdit = ({ data, goBack, classes }) => {
  const dispatch = useDispatch();
  const { value } = data[0];
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const onFinish = async (values) => {
    const formValues = { ...values, value: values.value.toString() };

    try {
      const updateRule = await setFirstTimeRule(formValues);
      if (updateRule.status === 200) {
        openNotification('success', 'Commission rule updated!');
        dispatch(commonActions.getfirstTimeConfig());
        goBack();
      }
    } catch (error) {
      // console.log('save new first time rule error', error);
      openNotification('error', 'New value could not be saved');
    }
  };

  return (
    <div>
      <h4>Edit First Time User rule</h4>
      <div className={classes.addWrap}>
        <Tooltip title="Go Back">
          <ArrowLeftOutlined
            className={classes.goBack__icon}
            onClick={goBack}
          />
        </Tooltip>
      </div>
      <div>
        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item
            name="value"
            label="Commission Value (€)"
            rules={[{ required: true }]}
          >
            <Input placeholder={value} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
