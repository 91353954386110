import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

export const SelectMultiUsers = ({ type, selected, handleSelect }) => {
  const [selOptions, setSelOptions] = useState(selected.userIds);

  useEffect(() => {
    handleSelect({ [type]: selOptions });
  }, [handleSelect, selOptions, type]);

  const selectProps = {
    mode: 'tags',
  };

  const handleOnSelect = (value, label) => {
    const addedItems = [...selOptions, parseInt(value)];

    setSelOptions(addedItems);
    handleSelect({
      [type]: addedItems,
    });
  };

  const handleOnDeselect = (value) => {
    const removedItems = selOptions.filter((item) => item !== value);
    setSelOptions(removedItems);
    handleSelect({
      [type]: removedItems,
    });
  };


  const selectedValues = selOptions;
  // console.log('selectValues', selectedValues);

  return (
    <Select
      {...selectProps}
      value={selectedValues}
      onSelect={handleOnSelect}
      onDeselect={handleOnDeselect}
      tokenSeparators={[',']} 
    />
  );
};
