import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from '../../../store/common.slice';
import { TableSearch } from '../../../containers/TableSearch';

export const FirstTimeList = () => {
  const { firstTime } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(commonActions.getfirstTime());
  }, [dispatch]);

  const adaptFirstTime = (ftData) => {
    return ftData.map((ft) => ({
        entryId: ft.id,
        paidToUser: ft.paidToUserId,
        leadId: ft.leadId,
        valuePaid: `€${ft.paid}`,
        date: ft.created,
      }) 
    );
  };

  return (
    <div>
      <TableSearch
        title="First Time users paid commissions"
        data={adaptFirstTime(firstTime)}
        rowKey="id"
      />
    </div>
  );
};
