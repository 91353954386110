import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { partnersActions } from '../../../store/partners.slice';
import { Tooltip } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { TableEdit } from '../../TableEdit';
import { ExceptionLevelsForm } from './ExceptionLevelsForm';
import { Exceptions as ExceptionsService } from '../../../services/partners.serv';
import { openNotification } from '../../../utils/notify.utils';

import classes from './Exceptions.module.scss';

export const ExceptionLevels = ({ exceptId, exceptions }) => {
  const [creating, setCreating] = useState(false);
  const { exceptionLevels } = useSelector((state) => state.partners);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(partnersActions.getExceptionLevels());
  }, [dispatch]);

  const handleAddNew = () => {
    setCreating(!creating);
  };

  const handleEditLevel = async (id, exceptData) => {
    const updateData = {...exceptData };

    try {
      const update = await ExceptionsService.updateCommissionExceptionLevel(
        id,
        updateData
      );

      if (update.status === 200) {
        openNotification('success', 'Updated!');
        dispatch(partnersActions.getExceptionLevels());
      }
    } catch (error) {
      // console.log('ERROR updating Exception level', error);
      openNotification('error', 'Ups! Could not update...');
    }
  };

  const handleRemoveLevel = async (exceptLevelId) => {
    try {
      const remove = await ExceptionsService.deleteCommissionExceptionLevel(
        exceptLevelId
      );
      if (remove.status === 200) {
        openNotification('success', 'The exception level was deleted!');
        dispatch(partnersActions.getExceptionLevels());
      }
    } catch (error) {
      // console.log('ERROR deleting Group', error);
      openNotification(
        'error',
        'Ups! Could not delete this exception level...'
      );
    }
  };

  const levels = exceptionLevels.filter((level) => level.exceptId === exceptId);

  return (
    <div className={classes.wrapElem}>
      <h4>Levels</h4>
      {creating && (
        <ExceptionLevelsForm exceptId={exceptId} goBack={handleAddNew} />
      )}
      {!creating && (
        <>
          <div className={classes.addWrap}>
            <Tooltip title="Add Exception Level">
              <PlusCircleOutlined
                className={classes.addNew__icon}
                onClick={handleAddNew}
              />
            </Tooltip>
            <span className={classes.addNew__text}>
              Add New Exception Level
            </span>
          </div>
          <TableEdit
            list={levels}
            update={handleEditLevel}
            remove={handleRemoveLevel}
          />
        </>
      )}
    </div>
  );
};
