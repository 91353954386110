import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientsActions } from '../../../store/clients.slice';
import { commonActions } from '../../../store/common.slice';
import { Tooltip } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { TableEdit } from '../../TableEdit';
import { GroupsForm } from './GroupsForm';
import { Groups as GroupService } from '../../../services/clients.serv';
import { openNotification } from '../../../utils/notify.utils';
import classes from './Groups.module.scss';

export const Groups = () => {
  const [creating, setCreating] = useState(false);
  const { groups } = useSelector((state) => state.clients);
  const { categories } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clientsActions.getGroups());
    dispatch(commonActions.getCategories());
  }, [dispatch]);

  const cleanGroups = (groups, categories) => {
    if (groups.length > 0 && categories.length > 0) {
      return groups.map((group) => {
        const categLabel = group.categories.map((categId) => {
          const found = categories.find(
            ({ tid }) => parseInt(tid) === parseInt(categId)
          );
          if (found) {
            return { value: found.tid, label: found.name };
          }

          return { value: categId, label: '' };
        });

        return { ...group, categories: categLabel };
      });
    }
    return [];
  };

  const tableGroups = cleanGroups(groups, categories);

  const handleEdit = async (id, groupData) => {
    // revert categories data to ids
    const updateData = {
      ...groupData,
      categories: groupData.categories.map((categ) => parseInt(categ.value)),
    };

    // dispatch(clientsActions.updateGroups({id, updateData}));

    try {
      const update = await GroupService.updateCommissionGroup(id, updateData);
      if (update.status === 200) {
        openNotification('success', 'Updated!');
        dispatch(clientsActions.getGroups());
      }
    } catch (error) {
      // console.log('ERROR updating Group', error);
      openNotification('error', 'Ups! Could not update...');
    }
  };

  const handleRemove = async (groupId) => {
    try {
      const remove = await GroupService.deleteCommissionGroup(groupId);
      if (remove.status === 200) {
        dispatch(clientsActions.getGroups());
        openNotification('success', 'The group was deleted!');
      }
    } catch (error) {
      // console.log('ERROR deleting Group', error);
      openNotification('error', 'Ups! Could not delete this group ...');
    }
  };

  const handleAddNew = () => {
    setCreating(!creating);
  };

  return (
    <div>
      <h3>Groups</h3>
      {creating && <GroupsForm goBack={handleAddNew} categories={categories} />}
      {!creating && (
        <>
          {groups.length === 0 && (
            <div className={classes.addWrap}>
              <Tooltip title="Add Group">
                <PlusCircleOutlined
                  className={classes.addNew__icon}
                  onClick={handleAddNew}
                />
              </Tooltip>
              <span className={classes.addNew__text}>Add New Group</span>
            </div>
          )}
          <TableEdit
            list={tableGroups}
            update={handleEdit}
            remove={handleRemove}
          />
        </>
      )}
    </div>
  );
};
