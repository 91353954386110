export const buildColumns = (data, editable = false) => {
  const keys = Object.keys(data[0]);
  const cleanKeys = keys.filter(
    (key) => !['id', 'key', 'levelId', 'exceptId', 'ogDate'].includes(key)
  );
  return cleanKeys.map((key) => {
    const title = cleanColumnName(key);
    return {
      title,
      dataIndex: key,
      key: key,
      editable,
    };
  });
};

export const buildOrderedColumns = (data, editable = false) => {
  const keys = Object.keys(data[0]);
  const cleanKeys = keys.filter((key) => !['id', 'ogDate'].includes(key));
  return cleanKeys.map((key) => {
    // sorter by key
    const title = cleanColumnName(key);
    const columns = {
      title,
      dataIndex: key,
      key: key,
      editable,
    };

    if (!['level'].includes(key)) {
      columns.sorter = (a, b) => sortByKey(key, a[key], b[key]);
    }

    if (key === 'date') {
      columns.render = (value, row, index) => dateRenderer(value);
    }

    return columns;
  });
};

export const addKeys = (data) => {
  return data.map((item) => {
    const listKey = item.levelId ? item.levelId : item.id;
    return { ...item, key: listKey };
  });
};

function dateRenderer(date) {
  const readableDate = new Date(date).toLocaleString();
  if (readableDate === 'Invalid Date') return date;

  return readableDate;
}

function cleanColumnName(key) {
  let name = key;
  switch (name) {
    case 'comm_group':
      name = 'group';
      break;
    case 'nr_sells':
      name = 'Sell Count';
      break;
    case 'exceptName':
      name = 'Exception Name';
      break;
    case 'userIds':
      name = 'User Ids';
      break;
    default:
      break;
  }
  const uppercased = name[0].toLocaleUpperCase() + name.substring(1);
  return uppercased;
}

function sortByKey(key, valueA, valueB) {
  if (key === 'date') {
    const valAToDate = !Date.parse(valueA)
      ? new Date(dateSortHelper(valueA))
      : new Date(valueA);

    const valBToDate = !Date.parse(valueB)
      ? new Date(dateSortHelper(valueB))
      : new Date(valueB);

    let delta = Math.abs(valAToDate - valBToDate) / 1000;
    // days
    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // hours
    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // minutes
    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // seconds
    let seconds = delta % 60;

    if (!hours) return days;
    if (!minutes) return hours;
    if (!seconds) return minutes;
    return seconds;
  }

  return valueA - valueB;
}

function dateSortHelper(dateValue) {
  const dateArr = dateValue.split(', ');
  const datePartArr = dateArr[0].split('/');
  const fixedDate = [datePartArr[2], datePartArr[1], datePartArr[0]].join('-');

  return `${fixedDate} ${dateArr[1]}`;
}
