import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  ShopOutlined,
  DashboardOutlined,
  EuroOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import classes from './Sidebar.module.scss';

const { Sider } = Layout;

export const Sidebar = ({ collapsed }) => {
  const pathName = window.location.pathname;
  const menuItems = [
    {
      key: 1,
      url: '/dashboard',
      name: 'Dashboard',
      icon: <DashboardOutlined />,
    },
    {
      key: 2,
      url: '/partners',
      name: 'Partners',
      icon: <ShopOutlined />,
    },
    {
      key: 3,
      url: '/clients',
      name: 'Clients',
      icon: <UserOutlined />,
    },
    {
      key: 4,
      url: '/commissions',
      name: 'Commissions',
      icon: <EuroOutlined />,
    },
    {
      key: 5,
      url: '/first-time',
      name: 'First time clients',
      icon: <UsergroupAddOutlined />,
    },
    {
      key: 6,
      url: '/settings',
      name: 'Settings',
      icon: <SettingOutlined />,
    },
  ];

  const defaultSelected = menuItems.find((item) => item.url === pathName);
  const defaultSelectedKeys = defaultSelected
    ? [defaultSelected.key.toString()]
    : ['1'];

  return (
    <Sider
      className={classes.root}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div className={classes.logo} />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
      >
        {menuItems.map(({ key, icon, url, name }) => (
          <Menu.Item key={key} icon={icon}>
            <Link to={url}>{name}</Link>
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};
