import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { commonActions } from '../../../store/common.slice';
import { TableSimple } from '../../../containers/TableSimple';
import classes from './FirstTimeConfig.module.scss';
import { FirstTimeConfigEdit } from './FirstTimeConfigEdit';

export const FirstTimeConfig = () => {
  const [creating, setCreating] = useState(false);
  const { firstTimeConfig } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!firstTimeConfig.length) {
      dispatch(commonActions.getfirstTimeConfig());
    }
  }, [dispatch, firstTimeConfig]);

  const handleEditRule = () => {
    setCreating(!creating);
  }

  return (
    <div>
      {creating && <FirstTimeConfigEdit data={firstTimeConfig} classes={classes} goBack={handleEditRule} />}
      {!creating && (
        <>
          <h1>Configuration</h1>
          <p>First time clients commission rule</p>
          <div>
          <div className={classes.addWrap}>
                <Tooltip title="Edit rule">
                  <SettingOutlined
                    className={classes.addNew__icon}
                    onClick={handleEditRule}
                  />
                </Tooltip>
                <span className={classes.addNew__text}>Edit rule</span>
              </div>
          </div>
          <TableSimple title="" data={firstTimeConfig} rowKey="id" />
        </>
      )}
    </div>
  );
}
