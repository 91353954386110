import { call, put, takeLatest } from 'redux-saga/effects';
import { commonActions } from '../../store/common.slice';
import { getIsDry } from '../../services/common.serv';

function* fetchIsDryConfig() {
  try {
    const getMConfig = () => getIsDry();
    const { data } = yield call(getMConfig);

    yield put(commonActions.getIsDrySuccess(data));
  } catch (error) {
    yield put(commonActions.getIsDryError(error));
  }
}

export function* configSaga() {
  yield takeLatest(commonActions.getIsDry.type, fetchIsDryConfig);
  
}