import React from 'react';
import { useDispatch } from 'react-redux';
import { partnersActions } from '../../../store/partners.slice';
import { Form, Input, Button, Select, Tooltip, InputNumber } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Exceptions as ExceptionsService } from '../../../services/partners.serv';
import { openNotification } from '../../../utils/notify.utils';
import classes from './Exceptions.module.scss';

const { Option } = Select;

export const ExceptionLevelsForm = ({ goBack, exceptId }) => {
  const dispatch = useDispatch();

  const types = [
    { value: 'PERCENT', label: 'Percentage' },
    { value: 'FIXED', label: 'Fixed Value' },
  ];

  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const onFinish = async (values) => {
    const formValues = { exceptId, ...values };

    try {
      const newExceptLevel = await ExceptionsService.createCommissionExceptionLevels(
        formValues
      );
      if (newExceptLevel.status === 200) {
        openNotification('success', 'New Group Saved');
        dispatch(partnersActions.getExceptionLevels());
        goBack();
      }
    } catch (error) {
      // console.log('save new group error', error);
      openNotification('error', 'New Group Could not be Saved');
    }
  };

  const onReset = () => {
    form.resetFields();
    openNotification('info', 'Form cleared');
  };

  return (
    <div>
      <h4>Add new</h4>
      <div className={classes.addWrap}>
        <Tooltip title="Go Back">
          <ArrowLeftOutlined
            className={classes.goBack__icon}
            onClick={goBack}
          />
        </Tooltip>
      </div>
      <div>
        <Form
          {...layout}
          form={form}
          requiredMark="optional"
          name="control-hooks"
          onFinish={onFinish}
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input placeholder="Insert level name" />
          </Form.Item>

          <Form.Item name="level" label="Level" rules={[{ required: true }]}>
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item name="type" label="Type" rules={[{ required: true }]}>
            <Select
              placeholder="Select a type"
              allowClear
            >
              {types.map((type) => (
                <Option
                  key={type.value}
                  value={type.value}
                  title={type.label}
                  lablel={type.label}
                >
                  {type.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="value" label="Value" rules={[{ required: true }]}>
            <Input placeholder="Insert value" />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
