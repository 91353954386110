import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import partnerSlice from './partners.slice';
import clientSlice from './clients.slice';
import authSlice from './auth.slice';
import commonSlice from './common.slice';
import { commonSaga } from '../page/dashboard';
import { partnersRulesSaga } from '../page/partners';
import { clientsRulesSaga } from '../page/clients';
import { commissionsSaga } from '../page/commissions';
import { configFirstTimeSaga } from '../page/first-time/config';
import { configSaga } from '../page/config';
import { firstTimeSaga } from '../page/first-time/list';

import { userLoginSaga } from '../page/login';

const sagas = [
  commonSaga,
  userLoginSaga,
  partnersRulesSaga,
  clientsRulesSaga,
  commissionsSaga,
  configFirstTimeSaga,
  firstTimeSaga,
  configSaga,
];

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    auth: authSlice,
    partners: partnerSlice,
    clients: clientSlice,
    common: commonSlice,
  },
  middleware: [sagaMiddleware],
});

sagas.forEach((saga) => sagaMiddleware.run(saga));
