import React from 'react'
import { PageTabs } from '../../containers/PageTabs';
import { FirstTimeList } from './list'
import { FirstTimeConfig } from './config'

export const FirstTime = () => {
  const availableTabs = [
    {
      key: '1',
      name: 'List',
      element: <FirstTimeList />,
    },
    {
      key: '2',
      name: 'Config',
      element: <FirstTimeConfig />,
    },
  ];

  return (
    <div >
      <h1 data-testid="first-time">First Time Users</h1>
      <PageTabs tabs={availableTabs} />
    </div>
  )
}
