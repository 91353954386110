import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from '../../store/common.slice';
import { PageTabs } from '../../containers/PageTabs';
import { TableSearch } from '../../containers/TableSearch';
import classes from './Commissions.module.scss';

export const Commissions = () => {
  const { commissions } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(commonActions.getCommissions());
  }, [dispatch]);

  const cleanCommissions = useMemo(
    () =>
      commissions.map((commission) => {
        const levelParsed = commission.level
          ? JSON.parse(commission.level)
          : '';
        const levelValue =
          levelParsed?.type === 'PERCENT'
            ? `${levelParsed?.value}%`
            : `€${levelParsed?.value}`;

        return {
          id: commission?.id,
          userId: commission?.userId,
          leadId: commission?.leadId,
          value: `€${commission?.value}`,
          level: levelValue,
          commission: `€${commission?.amount}`,
          date: commission?.date,
        };
      }),
    [commissions]
  );

  const commissionTab = useMemo(() => (
    <div>
      <TableSearch title={`Commissions - ${cleanCommissions.length} calculated`} data={cleanCommissions} rowKey="id" />
    </div>
  ), [cleanCommissions]);

  const availableTabs = [
    {
      key: '1',
      name: 'Calculated',
      element: commissionTab,
    },
  ];

  return (
    <div className={classes.root}>
      <h1 data-testid="partners">Commissions</h1>
      <PageTabs tabs={availableTabs} />
    </div>
  );
};
