import React from 'react';
import { Layout, Tooltip } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import classes from './MainHeader.module.scss';

const { Header } = Layout;

export const MainHeader = ({ handleSidebar, collapsed }) => {
  const handleLogout = () => {
    localStorage.removeItem('tkn');
    window.location.reload();
  };

  return (
    <Header className={classes.root}>
      <div>
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: 'trigger',
            style: { fontSize: '1.2rem' },
            onClick: () => handleSidebar(!collapsed),
          }
        )}
      </div>
      <div>
        <h1 className={classes.title}>Affiliates Middleware</h1>
      </div>
      <Tooltip title="Logout">
        <div
          className={classes.logout}
          role="button"
          onKeyPress={handleLogout}
          onClick={handleLogout}
        >
          <LogoutOutlined style={{ fontSize: '1.2rem' }} />
        </div>
      </Tooltip>
    </Header>
  );
};
