import axios from 'axios';

const ApiUrl = process.env.REACT_APP_API_URL;
const authToken = process.env.REACT_APP_AUTH_TOKEN;
const authHeader = {
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
};

export const getBOCategories = () =>
  axios.get(`${ApiUrl}/backoffice/categories`, authHeader);

export const getCalculatedCommissions = () =>
  axios.get(`${ApiUrl}/pacients/commission`, authHeader);

// First time user - data
export const getFirstTimeComissions = () =>
  axios.get(`${ApiUrl}/pacients/first`, authHeader);

export const getFirstTimePaid = () =>
  axios.get(`${ApiUrl}/pacients/first/paid`, authHeader);

// First time user - rules
export const getFirstTimeRule = () =>
  axios.get(`${ApiUrl}/pacients/first/config`, authHeader);

export const setFirstTimeRule = (ftRule) =>
  axios.post(`${ApiUrl}/pacients/first/config`, ftRule, authHeader);

// Is dry - If the system runs the commission register on the BO application
export const getIsDry = () =>
  axios.get(`${ApiUrl}/pacients/option/dry`, authHeader);

export const setIsDry = (isDryData) =>
  axios.post(`${ApiUrl}/pacients/option/dry`, isDryData, authHeader);

// Extras
export const calculateAll = () =>
  axios.get(`${ApiUrl}/partners/commission/calculate/all`, authHeader);

export const setPacientLead = (leadData) =>
  axios.post(`${ApiUrl}/pacients`, leadData, authHeader);

export const setFirstTimeLead = (ftLeadData) =>
  axios.post(`${ApiUrl}/pacients/first`, ftLeadData, authHeader);

export const getFirstTimeComissionsBk = () =>
  axios.get(`${ApiUrl}/pacients/first/backup`, authHeader);
