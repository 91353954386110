import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/auth.slice';
import { Layout, Form, Input, Button } from 'antd';
import { MainFooter } from '../../containers/MainFooter';
import { openNotification } from '../../utils/notify.utils';
import classes from './Login.module.scss';

const { Content } = Layout;

export const Login = () => {
  const { error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      openNotification('error', error);
    }
  }, [error]);

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 10,
      span: 14,
    },
  };

  const onFinish = (values) => {
    const { username, password } = values;
    dispatch(authActions.login({ user: username, pw: password }));
  };

  return (
    <Layout className={classes.root}>
      <Content>
        <div className={classes.title}>
          <h1>Insparya Middleware Dashboard</h1>
          <h2>Login</h2>
        </div>
        <div className={classes.formWrap} data-testid="login">
          <Form
            {...layout}
            name="login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: 'Please input your username!' },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
      <MainFooter />
    </Layout>
  );
};
