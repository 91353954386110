import React from 'react';
import { Table } from 'antd';
import { buildColumns } from '../../utils/table.utils';

export const TableSimple = ({ title, data, rowKey='id', footer = null }) => {
  const tableColumns = data.length ? buildColumns(data) : [];

  return (
    <Table
      title={() => title}
      footer={() => footer}
      columns={tableColumns}
      dataSource={data}
      size="small"
      rowKey={rowKey}
    />
  );
};
