import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { partnersActions } from '../../../store/partners.slice';
import { Tooltip, Divider } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { TableEdit } from '../../TableEdit';
import { ExceptionsForm } from './ExceptionsForm';
import { Exceptions as ExceptionsService } from '../../../services/partners.serv';
import { ExceptionLevels } from './ExceptionLevels';
import { openNotification } from '../../../utils/notify.utils';

import classes from './Exceptions.module.scss';

export const Exceptions = () => {
  const [creating, setCreating] = useState(false);
  const { exceptions } = useSelector((state) => state.partners);
  // const { exceptionLevels } = useSelector((state) => state.partners);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(partnersActions.getExceptions());
    dispatch(partnersActions.getExceptionLevels());
  }, [dispatch]);

  const handleAddNew = () => {
    setCreating(!creating);
  };

  const handleEdit = async (id, exceptData) => {
    const updateData = {
      ...exceptData,
      userIds: exceptData.userIds.map((user) => parseInt(user)),
    };

    try {
      const update = await ExceptionsService.updateCommissionException(
        id,
        updateData
      );

      if (update.status === 200) {
        openNotification('success', 'Updated!');
        dispatch(partnersActions.getExceptions());
      }
    } catch (error) {
      // console.log('ERROR updating Group', error);
      openNotification('error', 'Ups! Could not update...');
    }
  };

  const handleRemove = async (exceptId) => {
    try {
      const remove = await ExceptionsService.deleteCommissionException(
        exceptId
      );
      if (remove.status === 200) {
        openNotification('success', 'The exception was deleted!');
        dispatch(partnersActions.getExceptions());
      }
    } catch (error) {
      // console.log('ERROR deleting Group', error);
      openNotification('error', 'Ups! Could not delete this exception...');
    }
  };

  return (
    <div>
      {/* <h3>Exceptions</h3> */}
      {creating && (
        <ExceptionsForm goBack={handleAddNew} exceptions={exceptions} />
      )}
      <div>
        {!creating &&
          exceptions &&
          exceptions.map((exception) => (
            <div key={exception.id}>
              <div className={classes.mainWrap}>
                <div className={classes.wrapElem}>
                  <h4>Exception</h4>
                  <div className={classes.addWrap}>
                    <Tooltip title="Add Exception">
                      <PlusCircleOutlined
                        className={classes.addNew__icon}
                        onClick={handleAddNew}
                      />
                    </Tooltip>
                    <span className={classes.addNew__text}>
                      Add New Exception
                    </span>
                  </div>
                  <TableEdit
                    list={[exception]}
                    update={handleEdit}
                    remove={handleRemove}
                  />
                </div>
                <ExceptionLevels
                  exceptId={exception.id}
                  exceptions={exceptions}
                />
              </div>
              <Divider />
            </div>
          ))}
      </div>
    </div>
  );
};
