import { call, put, takeLatest } from 'redux-saga/effects';
import { commonActions } from '../../../store/common.slice';
import { getFirstTimePaid } from '../../../services/common.serv';

function* fetchFirstTime() {
  try {
    const getFTData = () => getFirstTimePaid();
    const { data } = yield call(getFTData);

    yield put(commonActions.getFTSuccess(data));
  } catch (error) {
    yield put(commonActions.getFTError(error));
  }
}

export function* firstTimeSaga() {
  yield takeLatest(commonActions.getfirstTime.type, fetchFirstTime);
  
}
