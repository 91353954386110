import axios from 'axios';

const ApiUrl = process.env.REACT_APP_API_URL;
const authToken = process.env.REACT_APP_AUTH_TOKEN;
const authHeader = {
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
};
const baseUrl = `${ApiUrl}/partners`;

export const Groups = {
  getCommissionGroups: () =>
    axios.get(`${baseUrl}/commission/groups`, authHeader),
  createCommissionGroup: (group) =>
    axios.post(`${baseUrl}/commission/groups`, group, authHeader),
  updateCommissionGroup: (groupId, group) =>
    axios.put(`${baseUrl}/commission/groups/${groupId}`, group, authHeader),
  deleteCommissionGroup: (groupId) =>
    axios.delete(`${baseUrl}/commission/groups/${groupId}`, authHeader),
};

export const Levels = {
  getAllCommissionLevels: () =>
    axios.get(`${baseUrl}/commission/levels`, authHeader),
  getCommissionLevels: (groupId) =>
    axios.get(`${baseUrl}/commission/levels/${groupId}`, authHeader),
  createCommissionLevel: (level) =>
    axios.post(`${baseUrl}/commission/levels`, level, authHeader),
  updateCommissionLevel: (levelId, level) =>
    axios.put(`${baseUrl}/commission/levels/${levelId}`, level, authHeader),
  deleteCommissionLevel: (levelId) =>
    axios.delete(`${baseUrl}/commission/levels/${levelId}`, authHeader),
};

export const Exceptions = {
  getAllCommissionExceptions: () =>
    axios.get(`${baseUrl}/commission/exceptions`, authHeader),
  getCommissionException: (exceptId) =>
    axios.get(`${baseUrl}/commission/exceptions/${exceptId}`, authHeader),
  createCommissionExceptions: (exception) =>
    axios.post(`${baseUrl}/commission/exceptions`, exception, authHeader),
  updateCommissionException: (exceptId, exception) =>
    axios.put(
      `${baseUrl}/commission/exceptions/${exceptId}`,
      exception,
      authHeader
    ),
  deleteCommissionException: (exceptId) =>
    axios.delete(`${baseUrl}/commission/exceptions/${exceptId}`, authHeader),

  getAllCommissionExceptionLevels: () =>
    axios.get(`${baseUrl}/commission/exceptions-level`, authHeader),
  getCommissionExceptionLevel: (exceptId) =>
    axios.get(`${baseUrl}/commission/exceptions-level/${exceptId}`, authHeader),
  createCommissionExceptionLevels: (exception) =>
    axios.post(`${baseUrl}/commission/exceptions-level`, exception, authHeader),
  updateCommissionExceptionLevel: (exceptId, level) =>
    axios.put(
      `${baseUrl}/commission/exceptions-level/${exceptId}`,
      level,
      authHeader
    ),
  deleteCommissionExceptionLevel: (levelId) =>
    axios.delete(
      `${baseUrl}/commission/exceptions-level/${levelId}`,
      authHeader
    ),
};

export const getAllRules = () =>
  axios.get(`${baseUrl}/commission/rules`, authHeader);

export const getCategories = () =>
  axios.get(`${baseUrl}/categories`, authHeader);
