import { call, put, takeLatest } from 'redux-saga/effects';
import { clientsActions } from '../../store/clients.slice';
import { getAllRules, Groups, Levels, Time } from '../../services/clients.serv';

function* fetchRules() {
  try {
    const getClientsRules = () => getAllRules();
    const { data } = yield call(getClientsRules);

    yield put(clientsActions.getAllRulesSuccess(data));
  } catch (error) {
    yield put(clientsActions.getAllRulesError(error));
  }
}

// GROUPS
function* fetchGroups() {
  const { getCommissionGroups } = Groups;
  try {
    const getClientsGroups = () => getCommissionGroups();
    const { data } = yield call(getClientsGroups);

    yield put(clientsActions.getGroupsSuccess(data));
  } catch (error) {
    yield put(clientsActions.getGroupsError(error));
  }
}

// LEVELS
function* fetchLevels() {
  const { getAllCommissionLevels } = Levels;
  try {
    const getClientsLevels = () => getAllCommissionLevels();
    const { data } = yield call(getClientsLevels);

    yield put(clientsActions.getLevelsSuccess(data));
  } catch (error) {
    yield put(clientsActions.getLevelsError(error));
  }
}

// LEVELS
function* fetchTime() {
  const { getAllCommissionTime } = Time;
  try {
    const getClientsTime = () => getAllCommissionTime();
    const { data } = yield call(getClientsTime);

    yield put(clientsActions.getTimeSuccess(data));
  } catch (error) {
    yield put(clientsActions.getTimeError(error));
  }
}

export function* clientsRulesSaga() {
  yield takeLatest(clientsActions.getAllRules.type, fetchRules);
  yield takeLatest(clientsActions.getGroups.type, fetchGroups);
  yield takeLatest(clientsActions.getLevels.type, fetchLevels);
  yield takeLatest(clientsActions.getTime.type, fetchTime);
}
