import React, { useEffect, useState } from 'react';
import { Table, Form, Tag } from 'antd';
import { EditCell } from './EditCell';
import { ActionCell } from './ActionCell';
import { buildColumns } from '../../utils/table.utils';

const selectedInitialState = {
  categories: [],
  comm_group: 0,
  type: '',
  userIds: [],
};
export const TableEdit = ({ list, groups, update, remove }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(list);
  const [selected, setSelected] = useState(selectedInitialState);
  const [editingKey, setEditingKey] = useState('');
  const selectInputs = ['categories', 'comm_group', 'type', 'userIds'];

  useEffect(() => {
    setData(list);
  }, [list]);

  const isEditing = (record) => record.id === editingKey;

  // Define table columns from data
  const colFromData = data.length ? buildColumns(data, true) : [];

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const removeRow = (key) => {
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.id);

    if (index > -1) {
      // const item = newData[index];
      newData.splice(index, 1);
      setData(newData);
      setEditingKey('');
      remove(key);
    } else {
      setEditingKey('');
    }
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.id);

      // Add selected values to the inputs
      Object.keys(row).forEach((key) => {
        if (selectInputs.includes(key)) {
          row[key] = selected[key] || row[key];
        }
      });

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        setEditingKey('');
        update(item.id, row);
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      // console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    ...colFromData,
    {
      title: 'Actions',
      dataIndex: 'operation',
      width: '15%',
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <ActionCell
            record={record}
            save={save}
            edit={edit}
            cancel={cancel}
            removeRow={removeRow}
            editingKey={editingKey}
            editable={editable}
          />
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    const tagColors = [
      'magenta',
      'red',
      'volcano',
      'orange',
      'gold',
      'lime',
      'green',
      'cyan',
      'blue',
      'geekblue',
      'purple',
    ];

    if (!col.editable) {
      return col;
    }

    if (col.dataIndex === 'categories') {
      col.render = (record) =>
        record.map((rec) => {
          const randomColor =
            tagColors[Math.floor(Math.random() * tagColors.length)];
          return (
            <Tag key={rec.value} color={randomColor}>
              {rec.label}
            </Tag>
          );
        });
    }

    if (col.dataIndex === 'userIds') {
      col.render = (record) =>
        record.map((rec) => {
          const randomColor =
            tagColors[Math.floor(Math.random() * tagColors.length)];
          return (
            <Tag key={rec} color={randomColor}>
              {rec}
            </Tag>
          );
        });
    }

    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          inputType: selectInputs.includes(col.dataIndex) ? 'select' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          handleSelect: selectInputs.includes(col.dataIndex) && setSelected,
          selected: selected,
          groups,
        };
      },
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditCell,
          },
        }}
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
        size="small"
        rowKey="id"
      />
    </Form>
  );
};
