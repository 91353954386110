import { call, put, takeLatest } from 'redux-saga/effects';
import { partnersActions } from '../../store/partners.slice';
import {
  getAllRules,
  Groups,
  Levels,
  Exceptions,
} from '../../services/partners.serv';

function* fetchRules() {
  try {
    const getPartnersRules = () => getAllRules();
    const { data } = yield call(getPartnersRules);

    yield put(partnersActions.getAllRulesSuccess(data));
  } catch (error) {
    yield put(partnersActions.getAllRulesError(error));
  }
}

// GROUPS
function* fetchGroups() {
  const { getCommissionGroups } = Groups;
  try {
    const getPartnersGroups = () => getCommissionGroups();
    const { data } = yield call(getPartnersGroups);

    yield put(partnersActions.getGroupsSuccess(data));
  } catch (error) {
    yield put(partnersActions.getGroupsError(error));
  }
}

// LEVELS
function* fetchLevels() {
  const { getAllCommissionLevels } = Levels;
  try {
    const getPartnersLevels = () => getAllCommissionLevels();
    const { data } = yield call(getPartnersLevels);

    yield put(partnersActions.getLevelsSuccess(data));
  } catch (error) {
    yield put(partnersActions.getLevelsError(error));
  }
}

// EXCEPTIONS
function* fetchExceptions() {
  const { getAllCommissionExceptions } = Exceptions;
  try {
    const getPartnersExceptions = () => getAllCommissionExceptions();
    const { data } = yield call(getPartnersExceptions);

    yield put(partnersActions.getExceptionsSuccess(data));
  } catch (error) {
    yield put(partnersActions.getExceptionsError(error));
  }
}

// EXCEPTIONS LEVELS
function* fetchExceptionLevels() {
  const { getAllCommissionExceptionLevels } = Exceptions;
  try {
    const getPartnersExceptionLevels = () => getAllCommissionExceptionLevels();
    const { data } = yield call(getPartnersExceptionLevels);

    yield put(partnersActions.getExceptionLevelsSuccess(data));
  } catch (error) {
    yield put(partnersActions.getExceptionLevelsError(error));
  }
}

export function* partnersRulesSaga() {
  yield takeLatest(partnersActions.getAllRules.type, fetchRules);
  yield takeLatest(partnersActions.getGroups.type, fetchGroups);
  yield takeLatest(partnersActions.getLevels.type, fetchLevels);
  yield takeLatest(partnersActions.getExceptions.type, fetchExceptions);
  yield takeLatest(
    partnersActions.getExceptionLevels.type,
    fetchExceptionLevels
  );
}
