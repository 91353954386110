import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rules: [],
  groups: [],
  levels: [],
  time: [],
};

export const clientSlice = createSlice({
  name: 'client',
  initialState: initialState,
  reducers: {
    getAllRules: (state) => state,
    getAllRulesSuccess: (state, action) => {
      const { payload: rules } = action;
      return { ...state, rules };
    },
    getAllRulesError: (state, action) => state,

    getGroups: (state) => state,
    getGroupsSuccess: (state, action) => {
      const { payload: groups } = action;
      return { ...state, groups };
    },
    getGroupsError: (state) => state,

    getLevels: (state) => state,
    getLevelsSuccess: (state, action) => {
      const { payload: levels } = action;
      return { ...state, levels };
    },
    getLevelsError: (state) => state,

    getTime: (state) => state,
    getTimeSuccess: (state, action) => {
      const { payload: time } = action;
      return { ...state, time: [time] };
    },
    getTimeError: (state) => state,
  },
});

export const clientsActions = { ...clientSlice.actions };
export default clientSlice.reducer;
